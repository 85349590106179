import { Box, Button, HStack, Icon, useColorMode, VStack, Spacer } from "native-base";
import { MapView } from "~/components/Maps/MapView";
import React, {
  useMemo,
  useState,
  useRef,
  useReducer,
  MutableRefObject,
  useLayoutEffect,
} from "react";
import { BlurredBottomSheet } from "~/components/Blurred/BlurredBottomSheet";
import { useComponentSize } from "~/common/hooks/useComponentSize";
import { MapViewRef } from "~/components/Maps/Common";
import { BlurredBox } from "~/components/Blurred/BlurredBox";
import {
  SlideInLeft,
  SlideInRight,
  SlideOutLeft,
  SlideOutRight,
  useSharedValue,
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useWindowDimensions } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  LayersMenu,
  Legend,
  mapLegendAtom,
  mapStyleAtom,
  UnableToLoadDataBanner,
} from "./mapOverlays";
import { usePanelData } from "~/components/Panels/Panels";
import { useAtomValue } from "jotai";
import { disableMapsAtom } from "~/features/settings/debugging/screen";
import { MapPositionController } from "./mapPositionController";
import { MapLocateItems } from "~/features/locate/mapItems/locate";
import { MapTrackItems } from "./mapItems/track";

function MapFitButton({
  mapMoved,
  updateMapMoved,
  onPress,
}: {
  mapMoved: MutableRefObject<boolean>;
  updateMapMoved: MutableRefObject<(() => void) | null>;
  onPress(): void;
}) {
  const [, update] = useReducer((x) => x + 1, 0);

  useLayoutEffect(() => {
    updateMapMoved.current = () => update();
  }, [update]);

  if (mapMoved.current) {
    return (
      <Button
        pointerEvents="auto"
        key="zoom"
        colorScheme="primary"
        startIcon={<Icon size="md" as={MaterialCommunityIcons} name="fit-to-page" />}
        onPress={onPress}
      />
    );
  }
  return null;
}

export function Map() {
  const updateCameraPosition = useRef<() => void>(null);
  const updateMapMoved = useRef<(() => void) | null>(null);

  // Bottom Sheet
  const snapPoints = useMemo(() => [100, "70%"], []);
  const animatedIndex = useSharedValue(1);
  const [index, setIndex] = useState(0);

  // Map
  const showLegend = useAtomValue(mapLegendAtom);
  const mapStyle = useAtomValue(mapStyleAtom);
  const disableMaps = useAtomValue(disableMapsAtom);
  const { colorMode } = useColorMode();
  const mapRef = React.useRef<MapViewRef>(null);
  const mapMoved = useRef(false);

  function setMapMoved(value: boolean) {
    if (mapMoved.current !== value) {
      mapMoved.current = value;
      updateMapMoved.current?.();
    }
  }

  // Page Size
  const [pageSize, onPageLayout] = useComponentSize();
  const windowSize = useWindowDimensions();

  // Size
  const isDesktop = windowSize.width >= 800;
  const isLargeDesktop = windowSize.width >= 1200;

  // Panels
  const panels = usePanelData();
  const leftPanel = isLargeDesktop ? panels?.left : panels?.right ?? panels?.left;
  const leftPanelKey = isLargeDesktop ? panels?.leftKey : panels?.rightKey ?? panels?.leftKey;
  const rightPanel = isLargeDesktop ? panels?.right : null;
  const rightPanelKey = isLargeDesktop ? panels?.rightKey : null;

  // Safe Areas
  const safeArea = useSafeAreaInsets();

  // Map Padding
  const bottomSheetHeight = useMemo(() => {
    const height = pageSize.height;
    return [100, 0.7 * height][index];
  }, [pageSize.height, index]);
  const paddingLeft = isDesktop && leftPanel ? 408 + safeArea.left : 0;
  const paddingRight = isDesktop && rightPanel ? 408 + safeArea.right : 0;
  const paddingBottom = isDesktop ? 0 : bottomSheetHeight;

  return (
    <HStack style={{ flex: 1 }} onLayout={onPageLayout}>
      <Box flex={1} overflow="hidden">
        {!disableMaps && (
          <MapView
            colorScheme={colorMode === "dark" ? "dark" : "light"}
            mapStyle={mapStyle}
            key="map"
            padding={{
              top: 0,
              left: paddingLeft,
              right: paddingRight,
              bottom: paddingBottom,
            }}
            onMapLoad={() => {
              updateCameraPosition.current?.();
            }}
            onMapMove={() => {
              setMapMoved(true);
            }}
            ref={mapRef}
          >
            <MapLocateItems />

            <MapTrackItems />
          </MapView>
        )}
        <VStack
          space="2"
          pointerEvents="box-none"
          alignItems="stretch"
          position="absolute"
          safeAreaTop
          top="2"
          left={`${paddingLeft + 8}px`}
          right={`${paddingRight + 8}px`}
        >
          <UnableToLoadDataBanner />
          <MapPositionController
            ref={updateCameraPosition}
            mapRef={mapRef}
            mapMoved={mapMoved}
            setMapMoved={setMapMoved}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            paddingBottom={paddingBottom}
          />
          <HStack pointerEvents="box-none" space="2" alignItems="flex-start">
            <LayersMenu
              fitToContent={() => {
                setMapMoved(false);
                updateCameraPosition.current?.();
              }}
            />
            <MapFitButton
              updateMapMoved={updateMapMoved}
              mapMoved={mapMoved}
              onPress={() => {
                setMapMoved(false);
                updateCameraPosition.current?.();
              }}
            />
            <Spacer pointerEvents="box-none" />
            {showLegend && (
              <Box pointerEvents="none">
                <Legend />
              </Box>
            )}
          </HStack>
        </VStack>
      </Box>

      {isDesktop ? (
        <React.Fragment key="desktop">
          {!!leftPanel && (
            <BlurredBox
              key={leftPanelKey}
              entering={SlideInLeft}
              exiting={SlideOutLeft}
              style={{
                position: "absolute",
                top: 8 + safeArea.top,
                left: 8 + safeArea.left,
                bottom: 8 + safeArea.bottom,
                width: 400,
                borderRadius: 8,
              }}
            >
              <VStack flex={1}>{leftPanel}</VStack>
            </BlurredBox>
          )}
          {!!rightPanel && (
            <BlurredBox
              key={rightPanelKey}
              entering={SlideInRight}
              exiting={SlideOutRight}
              style={{
                position: "absolute",
                top: 8 + safeArea.top,
                right: 8 + safeArea.right,
                bottom: 22 + safeArea.bottom,
                width: 400,
                borderRadius: 8,
              }}
            >
              <VStack flex={1}>{rightPanel}</VStack>
            </BlurredBox>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment key="mobile">
          <BlurredBottomSheet
            key="bottomSheet"
            animatedIndex={animatedIndex}
            onChangeIndex={(i) => {
              setIndex(i);
            }}
            snapPoints={snapPoints}
          >
            {leftPanel}
          </BlurredBottomSheet>
        </React.Fragment>
      )}
    </HStack>
  );
}
