import {
  GoogleMap,
  LoadScript,
  OverlayView as GMOverlayView,
  Polyline as GMPolyline,
} from "@react-google-maps/api";
import {
  FitCoordinatesOptions,
  GoToCoordinateOptions,
  MapViewProps,
  MapViewRef,
  MarkerProps,
  PolylineProps,
} from "~/components/Maps/Common";
import { ForwardedRef, forwardRef, useEffect, useMemo, useRef } from "react";

const apiKey = process.env.GOOGLE_MAPS_WEB_KEY;

export const MapView = forwardRef(function (props: MapViewProps, ref: ForwardedRef<MapViewRef>) {
  const mapViewRef = useRef<GoogleMap | null>(null);
  const mapInstanceRef = useRef<google.maps.Map | null>(null);
  useEffect(() => {
    const mapRef: MapViewRef = {
      fitCoordinates(options: FitCoordinatesOptions) {
        if (!window.google) return;
        const bounds = new window.google.maps.LatLngBounds();
        for (const coordinate of options.coordinates) {
          bounds.extend(new window.google.maps.LatLng(coordinate[0], coordinate[1]));
        }
        const padding = {
          top: (options.padding?.top ?? 0) + (props.padding?.top ?? 0),
          right: (options.padding?.right ?? 0) + (props.padding?.right ?? 0),
          bottom: (options.padding?.bottom ?? 0) + (props.padding?.bottom ?? 0),
          left: (options.padding?.left ?? 0) + (props.padding?.left ?? 0),
        };
        mapInstanceRef.current?.fitBounds(bounds, padding);
      },
      goToCoordinate(options: GoToCoordinateOptions) {
        mapInstanceRef.current?.setZoom(options.zoom ?? 15);
        mapInstanceRef.current?.panTo({
          lat: options.latitude,
          lng: options.longitude,
        });
        if (props.padding)
          mapInstanceRef.current?.panBy(
            (props.padding.right - props.padding.left) / 2,
            (props.padding.bottom - props.padding.top) / 2
          );
      },
    };
    if (typeof ref === "function") {
      ref(mapRef);
    } else if (ref) {
      ref.current = mapRef;
    }
  }, [props.padding?.top, props.padding?.left, props.padding?.right, props.padding?.bottom]);
  const options = useMemo<google.maps.MapOptions>(
    () => ({
      center: { lat: 0, lng: 0 },
      zoom: 0,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      mapId:
        props.colorScheme === "dark"
          ? process.env.GOOGLE_MAPS_WEB_ID_DARK
          : process.env.GOOGLE_MAPS_WEB_ID_LIGHT,
      isFractionalZoomEnabled: true,
    }),
    [props.colorScheme, "google" in window]
  );
  return (
    <LoadScript version="beta" googleMapsApiKey={apiKey!}>
      <style>
        {`
        div[style*="margin: 0px 5px;"][style*="z-index: 10000"] {
          left: ${props.padding?.left ?? 0}px !important;
          bottom: ${props.padding?.bottom ?? 0}px !important;
        }
        div[style*="background-color: rgb(229, 227, 223);"] {
          background-color: ${props.colorScheme === "dark" ? "#17263c" : "#8ab4f8"} !important;
        }
        `}
      </style>
      <GoogleMap
        ref={mapViewRef}
        zoom={options.zoom!}
        center={options.center!}
        mapContainerStyle={{ flex: 1 }}
        mapTypeId={
          props.mapStyle === "hybrid"
            ? "hybrid"
            : props.mapStyle === "terrain"
            ? "terrain"
            : "roadmap"
        }
        options={options}
        onDrag={props.onMapMove}
        onZoomChanged={props.onMapMove}
        onHeadingChanged={() => {
          const heading = mapInstanceRef.current?.getHeading() ?? 0;
          if (heading !== 0) {
            setTimeout(() => {
              mapInstanceRef.current?.setHeading(0);
            });
          }
        }}
        onLoad={(map) => {
          mapInstanceRef.current = map;
          props.onMapLoad?.();
        }}
      >
        {props.children}
      </GoogleMap>
    </LoadScript>
  );
});

export function Marker(props: MarkerProps) {
  return (
    <GMOverlayView
      position={{
        lat: props.latitude,
        lng: props.longitude,
      }}
      mapPaneName={GMOverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        onClick={
          props.onPress
            ? (ev) => {
                ev.stopPropagation();
                props.onPress?.();
              }
            : undefined
        }
        style={{
          transform: `translateX(-${(props.anchor?.x ?? 0.5) * 100}%) translateY(-${
            (props.anchor?.y ?? 1) * 100
          }%)`,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: props.zIndex ?? 0,
        }}
      >
        {props.children}
      </div>
    </GMOverlayView>
  );
}

export function Polyline(props: PolylineProps) {
  return (
    <GMPolyline
      path={props.coordinates.map((coordinate) => ({
        lat: coordinate[0],
        lng: coordinate[1],
      }))}
      onClick={props.onPress}
      options={{
        zIndex: props.zIndex ?? 0,
        strokeWeight: props.width ?? 2,
        strokeColor: props.color ?? "#000000",
      }}
    />
  );
}
